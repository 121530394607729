export const columnsDataDevelopment = [
  {
    Header: "TYPE",
    accessor: "type",
  },
  {
    Header: "CCOMPANY NAME",
    accessor: "company_name",
  },
  {
    Header: "PHONE NUMBER",
    accessor: "phone_number",
  },
  {
    Header: "COMPANY TYPE",
    accessor: "company_type",
  },
  {
    Header: "LOCATION",
    accessor: "location",
  },
  {
    Header: "CREATED DATE",
    accessor: "createdAt",
  },
  {
    Header: "ACTION",
    accessor: "_id",
  },
];
