// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Image,
  Input,
  Link,
  Radio,
  RadioGroup,
  Select,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import React, { useCallback, useEffect, useState } from "react";
import axios from "utils/useAxios";

import Dropzone from "views/admin/profile/components/Dropzone";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import InputTag from "./TagInput";
import { useParams } from "react-router-dom";

export default function CreateBlog() {
  const brandColor = useColorModeValue("brand.500", "white");

  const { slug } = useParams();

  const [isLoading, setIsLoading] = useState(false);

  const submitType = slug ? "update" : "create";

  const [editorState, setEditorState] = useState(false);

  const [blog, setBlog] = useState({
    title: "",
    content: "",
    tags: [],
    image: null,
    slug: "",
    meta_description: "",
    meta_keywords: [],
    status: "active",
  });

  const updateBlog = (key, value) => setBlog({ ...blog, ...{ [key]: value } });

  const createBlog = async () => {
    setIsLoading(true);
    const response = await axios({
      apiPath: "blog",
      method: submitType === "create" ? "POST" : "PUT",
      payload: { ...blog },
    });
    setIsLoading(false);

    if (response && response.status)
      window.location.href = window.location.origin + "/#/admin/blogs";
  };

  const getDetail = async () => {
    const response = await axios({
      apiPath: "blog/" + slug,
      method: "GET",
    });

    if (response && response.status) {
      setBlog(response.data);
      setTimeout(() => {
        setEditorState(true);
      }, 500);
    }
  };

  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append("image", file);
    const response = await axios({
      apiPath: "upload/image",
      method: "POST",
      payload: formData,
      isFile: true,
    });
    if (response && response.status)
      updateBlog("image", response.data.image_url);
  };

  useEffect(() => {
    if (submitType === "update") return getDetail();
    setEditorState(true);
  }, []);

  // Chakra Color Mode
  return (
    <Card mb={{ base: "0px", lg: "20px" }} mt={20} align="center">
      <form>
        <SimpleGrid mb="20px" spacing={{ base: "20px", xl: "20px" }}>
          <FormControl id="title" isRequired>
            <FormLabel>Title</FormLabel>
            <Input
              placeholder="Title"
              borderRadius="16px"
              value={blog.title}
              onChange={(event) => updateBlog("title", event.target.value)}
            />
          </FormControl>
          <FormControl id="slug" isRequired>
            <FormLabel>Slug</FormLabel>
            <Input
              placeholder="Slug"
              borderRadius="16px"
              value={blog.slug}
              onChange={(event) => updateBlog("slug", event.target.value)}
            />
          </FormControl>
          {editorState && (
            <FormControl id="content" isRequired>
              <FormLabel>Content</FormLabel>
              <CKEditor
                editor={ClassicEditor}
                data={blog.content}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  updateBlog("content", data);
                }}
              />
            </FormControl>
          )}
          <FormControl id="image" isRequired mb={10}>
            <FormLabel>Image</FormLabel>
            <Dropzone
              content={
                <Box>
                  <Flex justify="center" mx="auto" mb="12px">
                    <Text fontSize="xl" fontWeight="700" color={brandColor}>
                      Upload Files
                    </Text>
                  </Flex>
                  <Text
                    fontSize="sm"
                    fontWeight="500"
                    color="secondaryGray.500">
                    PNG, JPG and GIF files are allowed
                  </Text>
                </Box>
              }
              onDrop={(file) => uploadImage(file[0])}
            />
          </FormControl>
          {blog.image && (
            <Box mb={{ base: "20px", "2xl": "20px" }}>
              <Image src={blog.image} w="48" h="48" borderRadius="20px" />
            </Box>
          )}
          <SimpleGrid columns={2} gap={10}>
            <FormControl id="tags" isRequired>
              <FormLabel>Tags</FormLabel>
              <InputTag
                dataInput={blog.tags}
                setDataIput={(value) => updateBlog("tags", value)}
              />
            </FormControl>
            <FormControl id="meta_keywords" isRequired>
              <FormLabel>Meta Keywords</FormLabel>
              <InputTag
                dataInput={blog.meta_keywords}
                setDataIput={(value) => updateBlog("meta_keywords", value)}
              />
            </FormControl>
          </SimpleGrid>
          <Flex>
            <FormControl id="meta_description" isRequired>
              <FormLabel>Meta Description</FormLabel>
              <Input
                placeholder="Meta Description"
                borderRadius="16px"
                value={blog.meta_description}
                onChange={(event) =>
                  updateBlog("meta_description", event.target.value)
                }
              />
            </FormControl>
          </Flex>
          <FormControl id="status" isRequired>
            <FormLabel>Status</FormLabel>
            <RadioGroup
              onChange={(value) => updateBlog("status", value)}
              value={blog.status}>
              <Stack direction="row">
                <Radio value="active">Active</Radio>
                <Radio value="passive">Passive</Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
          <Button
            isLoading={isLoading}
            onClick={() => createBlog()}
            variant="darkBrand"
            color="white"
            fontSize="sm"
            fontWeight="500"
            borderRadius="70px"
            px="24px"
            py="5px">
            {submitType === "create" ? "Create" : "Update"}
          </Button>
        </SimpleGrid>
      </form>
    </Card>
  );
}
