// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Image,
  Input,
  Link,
  Radio,
  RadioGroup,
  Select,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import React, { useCallback, useEffect, useState } from "react";
import axios from "utils/useAxios";

import Dropzone from "views/admin/profile/components/Dropzone";
import { useParams } from "react-router-dom";

export default function CreateMedium() {
  const brandColor = useColorModeValue("brand.500", "white");

  const { slug } = useParams();

  const [isLoading, setIsLoading] = useState(false);

  const submitType = slug ? "update" : "create";

  const [medium, setMedium] = useState({
    title: null,
    image: null,
    slug: null,
    status: "active",
  });

  const updateMedium = (key, value) =>
    setMedium({ ...medium, ...{ [key]: value } });

  const createMedium = async () => {
    setIsLoading(true);
    const response = await axios({
      apiPath: "medium",
      method: submitType === "create" ? "POST" : "PUT",
      payload: { ...medium },
    });
    setIsLoading(false);

    if (response && response.status)
      window.location.href = window.location.origin + "/#/admin/mediums";
  };

  const getDetail = async () => {
    const response = await axios({
      apiPath: "medium/" + slug,
      method: "GET",
    });

    if (response && response.status) setMedium(response.data);
  };

  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append("image", file);
    const response = await axios({
      apiPath: "upload/image",
      method: "POST",
      payload: formData,
      isFile: true,
    });
    if (response && response.status)
      updateMedium("image", response.data.image_url);
  };

  useEffect(() => {
    if (submitType === "update") getDetail();
  }, [slug]);

  // Chakra Color Mode
  return (
    <Card mb={{ base: "0px", lg: "20px" }} mt={20} align="center">
      <form>
        <SimpleGrid mb="20px" spacing={{ base: "20px", xl: "20px" }}>
          <FormControl id="title" isRequired>
            <FormLabel>Title</FormLabel>
            <Input
              placeholder="Title"
              borderRadius="16px"
              value={medium.title}
              onChange={(event) => updateMedium("title", event.target.value)}
            />
          </FormControl>
          <FormControl id="slug" isRequired>
            <FormLabel>Slug</FormLabel>
            <Input
              placeholder="Slug"
              borderRadius="16px"
              value={medium.slug}
              onChange={(event) => updateMedium("slug", event.target.value)}
            />
          </FormControl>
          <FormControl id="image" isRequired mb={10}>
            <FormLabel>Image</FormLabel>
            <Dropzone
              content={
                <Box>
                  <Flex justify="center" mx="auto" mb="12px">
                    <Text fontSize="xl" fontWeight="700" color={brandColor}>
                      Upload File
                    </Text>
                  </Flex>
                  <Text
                    fontSize="sm"
                    fontWeight="500"
                    color="secondaryGray.500">
                    PNG and JPG files are allowed
                  </Text>
                </Box>
              }
              onDrop={(file) => uploadImage(file[0])}
            />
          </FormControl>
          {medium.image && (
            <Box mb={{ base: "20px", "2xl": "20px" }}>
              <Image src={medium.image} w="48" h="48" borderRadius="20px" />
            </Box>
          )}
          <FormControl id="status" isRequired>
            <FormLabel>Status</FormLabel>
            <RadioGroup
              onChange={(value) => updateMedium("status", value)}
              value={medium.status}>
              <Stack direction="row">
                <Radio value="active">Active</Radio>
                <Radio value="passive">Passive</Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
          <Button
            isLoading={isLoading}
            onClick={() => createMedium()}
            variant="darkBrand"
            color="white"
            fontSize="sm"
            fontWeight="500"
            borderRadius="70px"
            px="24px"
            py="5px">
            {submitType === "create" ? "Create" : "Update"}
          </Button>
        </SimpleGrid>
      </form>
    </Card>
  );
}
