export const columnsDataDevelopment = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "EMAIL",
    accessor: "email",
  },
  {
    Header: "ROLE",
    accessor: "role",
  },
  {
    Header: "IS EMAIL VERIFIED",
    accessor: "is_email_verified",
  },
  {
    Header: "IS PHONE NUMBER VERIFIED",
    accessor: "is_phone_number_verified",
  },
  {
    Header: "CREATED DATE",
    accessor: "createdAt",
  },
  {
    Header: "UPDATED DATE",
    accessor: "updatedAt",
  },
  // {
  //   Header: "TECH",
  //   accessor: "tech",
  // },
  // {
  //   Header: "DATE",
  //   accessor: "date",
  // },
  // {
  //   Header: "PROGRESS",
  //   accessor: "progress",
  // },
];
