// Chakra imports
import {
  Avatar,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Link,
  Radio,
  RadioGroup,
  Select,
  SimpleGrid,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import React, { useCallback, useEffect, useState } from "react";
import axios from "utils/useAxios";

import Dropzone from "views/admin/profile/components/Dropzone";
import { useParams } from "react-router-dom";
import { MdAdd, MdRemove } from "react-icons/md";
import { CgTrash } from "react-icons/cg";
import toast, { LoaderIcon } from "react-hot-toast";
import { CUIAutoComplete } from "chakra-ui-autocomplete";
import { useDebounce } from "use-debounce";
import { PhoneIcon } from "@chakra-ui/icons";

export default function HomePageDetail() {
  const brandColor = useColorModeValue("brand.500", "white");

  const carousel_item = {
    image: null,
    title: "",
    description: "",
    buttonText: "",
    buttonHref: "",
  };

  const [isLoading, setIsLoading] = useState(false);
  const [homePageDetail, setHomePageDetail] = useState({
    carousel_data: [],
    our_favourited_pieces: [],
    trending_partners: [],
    featured_blogs: [],
  });

  const [artworks, setArtworks] = useState([]);
  const [users, setUsers] = useState([]);
  const [blogs, setBlogs] = useState([]);

  const [artworkSearchInput, setArtworkSearchInput] = useState("");
  const [partnerSearchInput, setPartnerSearchInput] = useState("");
  const [blogSearchInput, setBlogSearchInput] = useState("");

  const [debounceArtorkdSearch] = useDebounce(artworkSearchInput, 500);
  const [debouncedPartnerSearch] = useDebounce(partnerSearchInput, 500);
  const [debouncedBlogSearch] = useDebounce(blogSearchInput, 500);

  useEffect(() => {
    getArtworkList();
  }, [debounceArtorkdSearch]);

  useEffect(() => {
    getPartnerList();
  }, [debouncedPartnerSearch]);

  useEffect(() => {
    getBlogList();
  }, [debouncedBlogSearch]);

  const getBlogList = async () => {
    setIsLoading(true);

    const response = await axios({
      apiPath: "blog/list?search=" + blogSearchInput,
      method: "GET",
    });

    setIsLoading(false);

    if (response && response.status)
      setBlogs(
        response.data.blogs.map((artwork) => {
          return {
            value: artwork._id,
            label: artwork.title + ", " + artwork.slug,
          };
        })
      );
  };

  const getPartnerList = async () => {
    setIsLoading(true);

    const response = await axios({
      apiPath: "user/list?limit=5&search=" + partnerSearchInput,
      method: "GET",
    });

    setIsLoading(false);

    if (response && response.status)
      setUsers(
        response.data.map((artwork) => {
          return {
            value: artwork._id,
            label: artwork.name + ", " + artwork.slug,
          };
        })
      );
  };

  const getArtworkList = async () => {
    setIsLoading(true);

    const response = await axios({
      apiPath: "artwork/list?limit=5&search=" + artworkSearchInput,
      method: "GET",
    });

    setIsLoading(false);

    if (response && response.status)
      setArtworks(
        response.data.artworks.map((artwork) => {
          return {
            value: artwork._id,
            label: artwork.title + ", " + artwork.slug,
          };
        })
      );
  };

  const getHomePageDetail = async () => {
    setIsLoading(true);

    const response = await axios({
      apiPath: "home/data",
      method: "GET",
    });

    setIsLoading(false);

    response.data.our_favourited_pieces =
      response.data.our_favourited_pieces.map((artwork) => {
        return {
          value: artwork._id,
          label: artwork.title + ", " + artwork.slug,
        };
      });

    response.data.trending_partners = response.data.trending_partners.map(
      (item) => {
        return {
          value: item._id,
          label: item.name + ", " + item.slug,
        };
      }
    );

    response.data.featured_blogs = response.data.featured_blogs.map((item) => {
      return {
        value: item._id,
        label: item.title + ", " + item.slug,
      };
    });

    if (response && response.status) setHomePageDetail(response.data);
  };

  const updateHomeDetail = async () => {
    setIsLoading(true);

    const payload = { ...homePageDetail };

    payload.our_favourited_pieces = payload.our_favourited_pieces.map(
      (item) => item.value
    );

    payload.trending_partners = payload.trending_partners.map(
      (item) => item.value
    );

    payload.featured_blogs = payload.featured_blogs.map((item) => item.value);

    await axios({
      apiPath: "home",
      payload,
    });

    setIsLoading(false);
  };

  const updateData = (key, value) => {
    const data = { ...homePageDetail };

    data[key] = value;

    setHomePageDetail(data);
  };

  const updateCarouselData = (key, value, index) => {
    const data = { ...homePageDetail };

    data.carousel_data[index][key] = value;

    setHomePageDetail(data);
  };

  const addNewArray = (key, value) => {
    const data = { ...homePageDetail };

    data[key].push(value);

    setHomePageDetail(data);
  };

  const removeItem = (key, index) => {
    const data = { ...homePageDetail };

    data[key].splice(index, 1);

    setHomePageDetail(data);
  };

  const uploadImage = async (file, index) => {
    const formData = new FormData();
    formData.append("image", file);
    const response = await axios({
      apiPath: "upload/image",
      method: "POST",
      payload: formData,
      isFile: true,
    });
    if (response && response.status)
      updateCarouselData("image", response.data.image_url, index);
  };

  useEffect(() => {
    getHomePageDetail();
  }, []);

  const customRender = (selected) => {
    return (
      <Flex flexDir="row" alignItems="center">
        {isLoading ? <LoaderIcon /> : <Text>{selected}</Text>}
      </Flex>
    );
  };

  // Chakra Color Mode
  return (
    <Card mb={{ base: "0px", lg: "20px" }} mt={20}>
      <Tabs isFitted>
        <TabList mb="1em">
          <Tab>Caraousel Items</Tab>
          <Tab>Our Favourited Pieces</Tab>
          <Tab>Trending Partners</Tab>
          <Tab>Featured Blogs</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Button
              mb={10}
              variant="darkBrand"
              color={"white"}
              onClick={() => addNewArray("carousel_data", carousel_item)}>
              Add new
            </Button>
            {homePageDetail.carousel_data.map((item, index) => (
              <form key={index} style={{ marginBottom: 20 }}>
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Text fontWeight={"bold"}>{index + 1}.</Text>
                  <Button
                    mb={10}
                    variant="solid"
                    bgColor={"red.400"}
                    color={"white"}
                    onClick={() => removeItem("carousel_data", index)}>
                    <CgTrash />
                  </Button>
                </Box>
                <SimpleGrid columns={2} columnGap={10}>
                  <FormControl id="image" isRequired mb={10}>
                    <FormLabel>Image</FormLabel>
                    <Dropzone
                      content={
                        <Box>
                          <Flex justify="center" mx="auto" mb="12px">
                            <Text
                              fontSize="xl"
                              fontWeight="700"
                              color={brandColor}>
                              Upload File
                            </Text>
                          </Flex>
                          <Text
                            fontSize="sm"
                            fontWeight="500"
                            color="secondaryGray.500">
                            PNG and JPG files are allowed
                          </Text>
                        </Box>
                      }
                      onDrop={(file) => uploadImage(file[0], index)}
                    />
                  </FormControl>
                  <Box>
                    <Image src={item.image} w="32" h="32" borderRadius="20px" />
                  </Box>
                </SimpleGrid>
                <SimpleGrid columns={{ base: 1, lg: 4 }} columnGap={10}>
                  <FormControl id="title" isRequired>
                    <FormLabel>Title</FormLabel>
                    <Input
                      placeholder="Title"
                      borderRadius="16px"
                      value={item.title}
                      onChange={(event) =>
                        updateCarouselData("title", event.target.value, index)
                      }
                    />
                  </FormControl>
                  <FormControl id="description" isRequired>
                    <FormLabel>Description</FormLabel>
                    <Input
                      placeholder="Description"
                      borderRadius="16px"
                      value={item.description}
                      onChange={(event) =>
                        updateCarouselData(
                          "description",
                          event.target.value,
                          index
                        )
                      }
                    />
                  </FormControl>
                  <FormControl id="buttonText" isRequired>
                    <FormLabel>Button Text</FormLabel>
                    <Input
                      placeholder="Button Text"
                      borderRadius="16px"
                      value={item.buttonText}
                      onChange={(event) =>
                        updateCarouselData(
                          "buttonText",
                          event.target.value,
                          index
                        )
                      }
                    />
                  </FormControl>
                  <FormControl id="buttonHref" isRequired>
                    <FormLabel>Button Href</FormLabel>
                    <Input
                      placeholder="Button Href"
                      borderRadius="16px"
                      value={item.buttonHref}
                      onChange={(event) =>
                        updateCarouselData(
                          "buttonHref",
                          event.target.value,
                          index
                        )
                      }
                    />
                  </FormControl>
                </SimpleGrid>
              </form>
            ))}
          </TabPanel>
          <TabPanel>
            <Box display={"flex"} gap={10}>
              <CUIAutoComplete
                tagStyleProps={{
                  rounded: "full",
                }}
                label="Choose your favorite artworks"
                placeholder="Search artworks"
                items={artworks}
                renderCustomInput={(inputProps) => (
                  <Input
                    placeholder="Search artworks"
                    borderRadius="16px"
                    {...inputProps}
                    value={artworkSearchInput}
                    onChange={(event) =>
                      setArtworkSearchInput(event.target.value)
                    }
                  />
                )}
                createItemRenderer={customRender}
                itemRenderer={customRender}
                selectedItems={homePageDetail.our_favourited_pieces}
                onSelectedItemsChange={(changes) =>
                  updateData("our_favourited_pieces", changes.selectedItems)
                }
              />
            </Box>
          </TabPanel>
          <TabPanel>
            <Box display={"flex"} gap={10}>
              <CUIAutoComplete
                tagStyleProps={{
                  rounded: "full",
                }}
                label="Choose your trending partners"
                placeholder="Search user"
                items={users}
                renderCustomInput={(inputProps) => (
                  <Input
                    placeholder="Search user"
                    borderRadius="16px"
                    {...inputProps}
                    value={partnerSearchInput}
                    onChange={(event) =>
                      setPartnerSearchInput(event.target.value)
                    }
                  />
                )}
                createItemRenderer={customRender}
                itemRenderer={customRender}
                selectedItems={homePageDetail.trending_partners}
                onSelectedItemsChange={(changes) =>
                  updateData("trending_partners", changes.selectedItems)
                }
              />
            </Box>
          </TabPanel>
          <TabPanel>
            <Box display={"flex"} gap={10}>
              <CUIAutoComplete
                tagStyleProps={{
                  rounded: "full",
                }}
                label="Choose your featured blogs"
                placeholder="Search blog"
                items={blogs}
                renderCustomInput={(inputProps) => (
                  <Input
                    placeholder="Search blog"
                    borderRadius="16px"
                    {...inputProps}
                    value={blogSearchInput}
                    onChange={(event) => setBlogSearchInput(event.target.value)}
                  />
                )}
                createItemRenderer={customRender}
                itemRenderer={customRender}
                selectedItems={homePageDetail.featured_blogs}
                onSelectedItemsChange={(changes) =>
                  updateData("featured_blogs", changes.selectedItems)
                }
              />
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
      <SimpleGrid mb="20px" spacing={{ base: "20px", xl: "20px" }}>
        <Button
          isLoading={isLoading}
          onClick={() => updateHomeDetail()}
          variant="darkBrand"
          color="white"
          fontSize="sm"
          fontWeight="500"
          borderRadius="70px"
          px="24px"
          py="5px">
          Update
        </Button>
      </SimpleGrid>
    </Card>
  );
}
