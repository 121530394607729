import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
  MdAccountTree,
  MdPerson4,
  MdAccountCircle,
  MdContentCopy,
  MdDensityMedium,
  MdAlbum,
  MdArtTrack,
  MdArticle,
  MdCategory,
  MdBrowseGallery,
  MdHomeRepairService,
} from "react-icons/md";

// Admin Imports
import MainDashboard from "views/admin/default";
import NFTMarketplace from "views/admin/marketplace";
import Profile from "views/admin/profile";
import DataTables from "views/admin/dataTables";
import Users from "views/admin/users";
import RTL from "views/admin/rtl";

// Auth Imports
import SignInCentered from "views/auth/signIn";
import Blogs from "views/admin/blogs";
import CreateBlog from "views/admin/blogs/create";
import Mediums from "views/admin/mediums";
import Categories from "views/admin/categories";
import CreateMedium from "views/admin/mediums/create";
import CreateCategory from "views/admin/categories/create";
import ArtworkRequests from "views/admin/artwork-requests";
import ArtworkRequestDetail from "views/admin/artwork-requests/detail";
import PartnerRequests from "views/admin/partner-requests";
import PartnerRequestDetail from "views/admin/partner-requests/detail";
import HomePageDetail from "views/admin/home-page-detail";
import Auctions from "views/admin/auctions";
import { RiAuctionFill } from "react-icons/ri";
import CreateAuction from "views/admin/auctions/create";

const routes = [
  {
    name: "Dashboard",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: MainDashboard,
  },
  // {
  //   name: "NFT Marketplace",
  //   layout: "/admin",
  //   path: "/nft-marketplace",
  //   icon: (
  //     <Icon
  //       as={MdOutlineShoppingCart}
  //       width="20px"
  //       height="20px"
  //       color="inherit"
  //     />
  //   ),
  //   component: NFTMarketplace,
  //   secondary: true,
  // },
  // {
  //   name: "Data Tables",
  //   layout: "/admin",
  //   icon: <Icon as={MdBarChart} width="20px" height="20px" color="inherit" />,
  //   path: "/data-tables",
  //   component: DataTables,
  // },
  {
    name: "Home Page Detail",
    layout: "/admin",
    path: "/home-page-detail",
    icon: (
      <Icon
        as={MdHomeRepairService}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    component: HomePageDetail,
  },
  {
    name: "Users",
    layout: "/admin",
    path: "/users",
    icon: (
      <Icon as={MdAccountCircle} width="20px" height="20px" color="inherit" />
    ),
    component: Users,
  },
  {
    name: "Blogs",
    layout: "/admin",
    path: "/blogs",
    icon: (
      <Icon as={MdDensityMedium} width="20px" height="20px" color="inherit" />
    ),
    component: Blogs,
  },
  {
    name: "Mediums",
    layout: "/admin",
    path: "/mediums",
    icon: <Icon as={MdAlbum} width="20px" height="20px" color="inherit" />,
    component: Mediums,
  },
  // {
  //   name: "Categories",
  //   layout: "/admin",
  //   path: "/categories",
  //   icon: <Icon as={MdCategory} width="20px" height="20px" color="inherit" />,
  //   component: Categories,
  // },
  {
    name: "Pending Artwork Requests",
    layout: "/admin",
    path: "/artwork-requests",
    icon: <Icon as={MdArticle} width="20px" height="20px" color="inherit" />,
    component: ArtworkRequests,
  },
  {
    name: "Pending Artwork Request Detail",
    layout: "/admin",
    path: "/artwork-request/:slug",
    base_path: "artwork-request",
    hidden: true,
    icon: <Icon as={MdArticle} width="20px" height="20px" color="inherit" />,
    component: ArtworkRequestDetail,
  },
  {
    name: "Pending Partner Requests",
    layout: "/admin",
    path: "/partner-requests",
    icon: (
      <Icon as={MdBrowseGallery} width="20px" height="20px" color="inherit" />
    ),
    component: PartnerRequests,
  },
  {
    name: "Pending Partner Request Detail",
    layout: "/admin",
    path: "/partner-request/:id",
    base_path: "partner-request",
    hidden: true,
    icon: <Icon as={MdArticle} width="20px" height="20px" color="inherit" />,
    component: PartnerRequestDetail,
  },
  {
    name: "Create Auction",
    layout: "/admin",
    path: "/create-auction",
    hidden: true,
    icon: (
      <Icon as={RiAuctionFill} width="20px" height="20px" color="inherit" />
    ),
    component: CreateAuction,
  },
  {
    name: "Create Auction",
    layout: "/admin",
    path: "/auction/:slug",
    hidden: true,
    icon: (
      <Icon as={RiAuctionFill} width="20px" height="20px" color="inherit" />
    ),
    component: CreateAuction,
  },
  {
    name: "Auctions",
    layout: "/admin",
    path: "/auctions",
    icon: (
      <Icon as={RiAuctionFill} width="20px" height="20px" color="inherit" />
    ),
    component: Auctions,
  },
  {
    name: "Create Blog",
    layout: "/admin",
    path: "/create-blog",
    hidden: true,
    icon: (
      <Icon as={MdContentCopy} width="20px" height="20px" color="inherit" />
    ),
    component: CreateBlog,
  },
  {
    name: "Update Blog",
    layout: "/admin",
    path: "/blog/:slug",
    hidden: true,
    icon: (
      <Icon as={MdContentCopy} width="20px" height="20px" color="inherit" />
    ),
    component: CreateBlog,
  },
  {
    name: "Create Medium",
    layout: "/admin",
    path: "/create-medium",
    hidden: true,
    icon: (
      <Icon as={MdContentCopy} width="20px" height="20px" color="inherit" />
    ),
    component: CreateMedium,
  },
  {
    name: "Update Medium",
    layout: "/admin",
    path: "/medium/:slug",
    hidden: true,
    icon: (
      <Icon as={MdContentCopy} width="20px" height="20px" color="inherit" />
    ),
    component: CreateMedium,
  },
  // {
  //   name: "Create Category",
  //   layout: "/admin",
  //   path: "/create-category",
  //   hidden: true,
  //   icon: (
  //     <Icon as={MdContentCopy} width="20px" height="20px" color="inherit" />
  //   ),
  //   component: CreateCategory,
  // },
  // {
  //   name: "Update Category",
  //   layout: "/admin",
  //   path: "/category/:id",
  //   hidden: true,
  //   icon: (
  //     <Icon as={MdContentCopy} width="20px" height="20px" color="inherit" />
  //   ),
  //   component: CreateCategory,
  // },
  {
    name: "Profile",
    layout: "/admin",
    hidden: true,
    path: "/profile",
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    component: Profile,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "/sign-in",
    hidden: true,
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
  },
  // {
  //   name: "RTL Admin",
  //   layout: "/rtl",
  //   path: "/rtl-default",
  //   icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
  //   component: RTL,
  // },
];

export default routes;
