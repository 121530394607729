export const columnsDataDevelopment = [
  {
    Header: "SELLER EMAIL",
    accessor: "seller.email",
  },
  {
    Header: "SELLER ROLE",
    accessor: "seller.role",
  },
  {
    Header: "ARTIST",
    accessor: "artist",
  },
  {
    Header: "TITLE",
    accessor: "title",
  },
  // {
  //   Header: "YEAR",
  //   accessor: "year",
  // },
  {
    Header: "MEDIUM",
    accessor: "medium.title",
  },
  {
    Header: "CREATED DATE",
    accessor: "createdAt",
  },
  {
    Header: "UPDATED DATE",
    accessor: "updatedAt",
  },
  {
    Header: "ACTION",
    accessor: "slug",
  },
];
