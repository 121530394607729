// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Image,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Select,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import React, { useCallback, useEffect, useState } from "react";
import axios from "utils/useAxios";

import Dropzone from "views/admin/profile/components/Dropzone";
import { useParams } from "react-router-dom";

const rarities = {
  unique: "Unique",
  limited_edition: "Limited Edition",
  open_edition: "Open Edition",
  unknown_edition: "Unknown Edition",
};

export default function ArtworkRequestDetail() {
  const brandColor = useColorModeValue("brand.500", "white");

  const { slug } = useParams();

  const [isLoading, setIsLoading] = useState(false);

  const [status, setStatus] = useState(null);

  const [artwork, setArtwork] = useState({
    _id: "",
    artist: "",
    title: "",
    medium: "",
    artwork: "",
    year: "",
    materials: "",
    rarity: "",
    height: "",
    width: "",
    depth: "",
    unit_of_length: "",
    paid_price: "",
    currency: "",
    provenance: "",
    city: "",
    notes: "",
    images: [],
  });

  const getDetail = async () => {
    const response = await axios({
      apiPath: "artwork/" + slug,
      method: "GET",
    });

    if (response && response.status) setArtwork(response.data);
  };

  const responseRequest = async () => {
    setIsLoading(true);
    const response = await axios({
      apiPath: "request/artwork",
      payload: { artwork_id: artwork._id, status },
    });
    setIsLoading(false);
    if (response && response.status)
      window.location.href =
        window.location.origin + "/#/admin/artwork-requests";
  };

  useEffect(() => {
    getDetail();
  }, []);

  // Chakra Color Mode
  return (
    <Card mb={{ base: "0px", lg: "20px" }} mt={20} align="center">
      <SimpleGrid mb="20px" column={2} spacing={{ base: "20px", xl: "20px" }}>
        <SimpleGrid columns={2} gap={10}>
          <FormControl id="artist">
            <FormLabel>Artist</FormLabel>
            <Input borderRadius="16px" value={artwork.artist} />
          </FormControl>
          <FormControl id="year">
            <FormLabel>Year</FormLabel>
            <Input borderRadius="16px" value={artwork.year} />
          </FormControl>
        </SimpleGrid>
        <SimpleGrid columns={2} gap={10}>
          <FormControl id="title">
            <FormLabel>Title</FormLabel>
            <Input borderRadius="16px" value={artwork.title} />
          </FormControl>
          <FormControl id="medium">
            <FormLabel>Medium</FormLabel>
            <Input borderRadius="16px" value={artwork.medium.title} />
          </FormControl>
        </SimpleGrid>
        <SimpleGrid columns={2} gap={10}>
          <FormControl id="materials">
            <FormLabel>Materials</FormLabel>
            <Input borderRadius="16px" value={artwork.materials} />
          </FormControl>
          <FormControl id="rarity">
            <FormLabel>Rarity</FormLabel>
            <Input borderRadius="16px" value={rarities[artwork.rarity]} />
          </FormControl>
        </SimpleGrid>
        <SimpleGrid columns={4} gap={10}>
          <FormControl id="height">
            <FormLabel>Height</FormLabel>
            <Input borderRadius="16px" value={artwork.height} />
          </FormControl>
          <FormControl id="width">
            <FormLabel>Width</FormLabel>
            <Input borderRadius="16px" value={artwork.width} />
          </FormControl>
          <FormControl id="depth">
            <FormLabel>Depth</FormLabel>
            <Input borderRadius="16px" value={artwork.depth} />
          </FormControl>
          <FormControl id="unit_of_length" alignSelf={"center"}>
            <FormLabel></FormLabel>
            <RadioGroup value={artwork.unit_of_length}>
              <Stack direction="row">
                <Radio value="in">in</Radio>
                <Radio value="cm">cm</Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
        </SimpleGrid>
        <SimpleGrid columns={2} gap={10}>
          <FormControl id="paid_price">
            <FormLabel>Paid Price</FormLabel>
            <Input borderRadius="16px" value={artwork.paid_price} />
          </FormControl>
          <FormControl id="currency" alignSelf={"center"}>
            <FormLabel></FormLabel>
            <RadioGroup value={artwork.currency}>
              <Stack direction="row">
                <Radio value="usd">USD</Radio>
                <Radio value="eur">EUR</Radio>
                <Radio value="gbp">GBP</Radio>
              </Stack>
            </RadioGroup>
          </FormControl>
        </SimpleGrid>
        <SimpleGrid columns={2} gap={10}>
          <FormControl id="provenance">
            <FormLabel>Provenance</FormLabel>
            <Input borderRadius="16px" value={artwork.provenance} />
          </FormControl>
          <FormControl id="city">
            <FormLabel>City</FormLabel>
            <Input borderRadius="16px" value={artwork.city} />
          </FormControl>
        </SimpleGrid>
        <FormControl id="notes">
          <FormLabel>Notes</FormLabel>
          <Input borderRadius="16px" value={artwork.notes} />
        </FormControl>
        <FormControl id="images">
          <FormLabel>Images</FormLabel>
          {artwork.images.map((image) => (
            <Box mb={{ base: "20px", "2xl": "20px" }}>
              <Image src={image} w="48" h="48" borderRadius="20px" />
            </Box>
          ))}
        </FormControl>
        {artwork.seller && artwork.seller.role === "user" && (
          <Text color={"red.500"}>
            The seller's role is 'user', so approving this artwork will change
            the seller's role to 'artist'
          </Text>
        )}
        <SimpleGrid columns={2} gap={10}>
          <Button
            onClick={() => setStatus("accepted")}
            colorScheme="teal"
            variant="solid"
            fontSize="sm"
            fontWeight="500"
            borderRadius="70px"
            px="24px"
            py="5px">
            Accept
          </Button>
          <Button
            onClick={() => setStatus("declined")}
            colorScheme="red"
            variant="solid"
            fontSize="sm"
            fontWeight="500"
            borderRadius="70px"
            px="24px"
            py="5px">
            Decline
          </Button>
        </SimpleGrid>
      </SimpleGrid>
      <Modal
        isCentered
        isOpen={status !== null}
        onClose={() => setStatus(null)}>
        <ModalOverlay
          bg="blackAlpha.300"
          backdropFilter="blur(10px) hue-rotate(90deg)"
        />
        <ModalContent>
          <ModalHeader>Are you sure ?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>You are gonna {status} the artwork</Text>
          </ModalBody>
          <ModalFooter>
            <Button
              isLoading={isLoading}
              variant="darkBrand"
              onClick={() => responseRequest()}>
              I'm sure
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Card>
  );
}
