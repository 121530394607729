// Chakra imports
import { Box, SimpleGrid } from "@chakra-ui/react";
import DevelopmentTable from "./components/DevelopmentTable";
import { columnsDataDevelopment } from "./variables/columnsData";
import React, { useEffect, useState } from "react";
import axios from "utils/useAxios";

export default function ArtworkRequests() {
  const [artworkRequests, setArtworkRequests] = useState([]);

  const getArtworkRequests = async () => {
    const response = await axios({ apiPath: "request/artwork", method: "GET" });

    if (response && response.status) setArtworkRequests(response.data);
  };

  useEffect(() => {
    getArtworkRequests();
  }, []);
  // Chakra Color Mode
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid mb="20px" spacing={{ base: "20px", xl: "20px" }}>
        <DevelopmentTable
          columnsData={columnsDataDevelopment}
          tableData={artworkRequests}
        />
      </SimpleGrid>
    </Box>
  );
}
