// Chakra imports
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Link,
  SimpleGrid,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import React, { useEffect, useState } from "react";
import axios from "utils/useAxios";

import Nft2 from "assets/img/nfts/Nft2.png";
import Blog from "components/card/Blog";

export default function Blogs() {
  const [blogs, setBlogs] = useState([]);

  const getBlogs = async () => {
    const response = await axios({ apiPath: "blog/list", method: "GET" });

    console.log({ response });

    if (response && response.status) setBlogs(response.data.blogs);
  };

  useEffect(() => {
    getBlogs();
  }, []);
  // Chakra Color Mode
  return (
    <Card mb={{ base: "0px", lg: "20px" }} mt={20} align="center">
      {/* <form>
        <SimpleGrid mb="20px" spacing={{ base: "20px", xl: "20px" }}>
          <FormControl id="first-name" isRequired>
            <FormLabel>First name</FormLabel>
            <Input placeholder="First name" borderRadius="16px" />
          </FormControl>
        </SimpleGrid>
      </form> */}
      <Link
        href={"#/admin/create-blog"}
        alignSelf={"end"}
        mt={{
          base: "0px",
          md: "10px",
          lg: "0px",
          xl: "10px",
          "2xl": "0px",
        }}>
        <Button
          variant="darkBrand"
          color="white"
          fontSize="sm"
          fontWeight="500"
          borderRadius="70px"
          px="24px"
          py="5px">
          + Create
        </Button>
      </Link>
      <SimpleGrid columns={{ base: 1, md: 4 }} gap="20px">
        {blogs.map((blog) => (
          <Blog
            id={blog._id}
            name={blog.title}
            author={blog.author.name}
            createdAt={blog.createdAt}
            status={blog.status}
            image={blog.image}
            slug={blog.slug}
          />
        ))}
      </SimpleGrid>
    </Card>
  );
}
