// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Select,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import React, { useCallback, useEffect, useState } from "react";
import axios from "utils/useAxios";

import Dropzone from "views/admin/profile/components/Dropzone";
import { useParams } from "react-router-dom";

const rarities = {
  unique: "Unique",
  limited_edition: "Limited Edition",
  open_edition: "Open Edition",
  unknown_edition: "Unknown Edition",
};

export default function PartnerRequestDetail() {
  const brandColor = useColorModeValue("brand.500", "white");

  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(false);

  const [status, setStatus] = useState(null);

  const [password, setPassword] = useState(null);

  const [partner, setPartner] = useState({
    first_name: "",
    last_name: "",
    title: "",
    email: "",
    company_name: "",
    phone_number: "",
    company_type: "",
    location: "",
  });

  const getDetail = async () => {
    const response = await axios({
      apiPath: "partner/detail",
      payload: { partner_request_id: id },
    });

    if (response && response.status) setPartner(response.data);
  };

  const responseRequest = async () => {
    setIsLoading(true);
    const response = await axios({
      apiPath: "request/partner",
      payload: { partner_request_id: partner._id, status, password },
    });
    setIsLoading(false);
    if (response && response.status)
      window.location.href =
        window.location.origin + "/#/admin/partner-requests";
  };

  useEffect(() => {
    getDetail();
  }, []);

  // Chakra Color Mode
  return (
    <Card mb={{ base: "0px", lg: "20px" }} mt={20} align="center">
      <SimpleGrid mb="20px" column={2} spacing={{ base: "20px", xl: "20px" }}>
        <SimpleGrid columns={2} gap={10}>
          <FormControl id="first_name">
            <FormLabel>First Name</FormLabel>
            <Input borderRadius="16px" value={partner.first_name} />
          </FormControl>
          <FormControl id="last_name">
            <FormLabel>Last Name</FormLabel>
            <Input borderRadius="16px" value={partner.last_name} />
          </FormControl>
        </SimpleGrid>
        <SimpleGrid columns={2} gap={10}>
          <FormControl id="title">
            <FormLabel>Title</FormLabel>
            <Input borderRadius="16px" value={partner.title} />
          </FormControl>
          <FormControl id="email">
            <FormLabel>Email</FormLabel>
            <Input borderRadius="16px" value={partner.email} />
          </FormControl>
        </SimpleGrid>
        <SimpleGrid columns={2} gap={10}>
          <FormControl id="company_name">
            <FormLabel>Company Name</FormLabel>
            <Input borderRadius="16px" value={partner.company_name} />
          </FormControl>
          <FormControl id="phone_number">
            <FormLabel>Phone Number</FormLabel>
            <Input borderRadius="16px" value={partner.phone_number} />
          </FormControl>
        </SimpleGrid>
        <SimpleGrid columns={2} gap={10}>
          <FormControl id="company_type">
            <FormLabel>Company Type</FormLabel>
            <Input borderRadius="16px" value={partner.company_type} />
          </FormControl>
          <FormControl id="location">
            <FormLabel>Location</FormLabel>
            <Input borderRadius="16px" value={partner.location} />
          </FormControl>
        </SimpleGrid>
        {partner.seller && partner.seller.role === "user" && (
          <Text color={"red.500"}>
            The seller's role is 'user', so approving this partner will change
            the seller's role to 'artist'
          </Text>
        )}
        <SimpleGrid columns={2} gap={10}>
          <Button
            onClick={() => setStatus("accepted")}
            colorScheme="teal"
            variant="solid"
            fontSize="sm"
            fontWeight="500"
            borderRadius="70px"
            px="24px"
            py="5px">
            Accept
          </Button>
          <Button
            onClick={() => setStatus("declined")}
            colorScheme="red"
            variant="solid"
            fontSize="sm"
            fontWeight="500"
            borderRadius="70px"
            px="24px"
            py="5px">
            Decline
          </Button>
        </SimpleGrid>
      </SimpleGrid>
      <Modal
        isCentered
        isOpen={status !== null}
        onClose={() => setStatus(null)}>
        <ModalOverlay
          bg="blackAlpha.300"
          backdropFilter="blur(10px) hue-rotate(90deg)"
        />
        <ModalContent>
          <ModalHeader>Are you sure ?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {status === "accepted" && (
              <Box>
                <Text>
                  A user will be created in the 'gallery' role with verified
                  email, phone and id fields.
                </Text>
                <InputGroup size="md" my={3}>
                  <Input
                    pr="4.5rem"
                    type={show ? "text" : "password"}
                    placeholder="Enter password"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                  />
                  <InputRightElement width="4.5rem">
                    <Button h="1.75rem" size="sm" onClick={handleClick}>
                      {show ? "Hide" : "Show"}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </Box>
            )}
            <Text>You are gonna {status} the partner</Text>
          </ModalBody>
          <ModalFooter>
            <Button
              isLoading={isLoading}
              variant="darkBrand"
              onClick={() => responseRequest()}>
              I'm sure
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Card>
  );
}
