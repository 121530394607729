// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Image,
  Input,
  Link,
  Radio,
  RadioGroup,
  Select,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import React, { useCallback, useEffect, useState } from "react";
import axios from "utils/useAxios";
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from "@choc-ui/chakra-autocomplete";
import AsyncSelect from "react-select/async";
import Dropzone from "views/admin/profile/components/Dropzone";
import { useParams } from "react-router-dom";
import { CgCross, CgRemove, CgTrash } from "react-icons/cg";
import { RxCross2 } from "react-icons/rx";
import { useDebounce } from "use-debounce";
import { CUIAutoComplete } from "chakra-ui-autocomplete";
import { LoaderIcon } from "react-hot-toast";
import moment from "moment";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default function CreateAuction() {
  const brandColor = useColorModeValue("brand.500", "white");

  const { slug } = useParams();

  const [isLoading, setIsLoading] = useState(false);

  const submitType = slug ? "update" : "create";

  const [auction, setAuction] = useState({
    title: "",
    description: "",
    image: "",
    starting_date: "",
    ending_date: "",
    lots: [],
  });

  const [editorState, setEditorState] = useState(false);

  const getArtworkList = async (inputValue, callback) => {
    setIsLoading(true);

    const response = await axios({
      apiPath: "artwork/list?limit=5&search=" + inputValue,
      method: "GET",
    });

    setIsLoading(false);

    if (response && response.status) {
      response.data.artworks = response.data.artworks.map((artwork) => {
        return {
          value: artwork._id,
          label:
            artwork.artist +
            ", " +
            artwork.title +
            "(" +
            artwork.year +
            ") " +
            artwork.paid_price +
            artwork.currency,
        };
      });

      if (callback) callback(response.data.artworks);
    }
  };

  const getLabel = (artwork) => {
    return (
      artwork.artist +
      ", " +
      artwork.title +
      "(" +
      artwork.year +
      ") " +
      artwork.paid_price +
      artwork.currency
    );
  };

  useEffect(() => {
    getArtworkList();
  }, []);

  const addLot = () => {
    const data = { ...auction };

    const payload = {
      artwork: "",
      starting_date: "",
      ending_date: "",
      estimated_starting_price: null,
      estimated_ending_price: null,
      starting_lot_price: null,
    };

    if (auction.starting_date) payload.starting_date = auction.starting_date;
    if (auction.ending_date) payload.ending_date = auction.ending_date;

    data.lots.push(payload);

    setAuction(data);
  };

  const removeLot = (index) => {
    const data = { ...auction };

    data.lots.splice(index, 1);

    setAuction(data);
  };

  const updateLot = (index, key, value) => {
    const data = { ...auction };

    data.lots[index][key] = value;

    setAuction(data);
  };

  const updateAuction = (key, value) =>
    setAuction({ ...auction, ...{ [key]: value } });

  const createAuction = async () => {
    const payload = { ...auction };

    if (submitType === "update") {
      payload.lots = payload.lots.map((lot) => {
        if (lot.artwork._id) return { ...lot, artwork: lot.artwork._id };

        return lot;
      });
    }

    setIsLoading(true);
    const response = await axios({
      apiPath: "auction",
      method: submitType === "create" ? "POST" : "PUT",
      payload,
    });
    setIsLoading(false);

    if (response && response.status)
      window.location.href = window.location.origin + "/#/admin/auctions";
  };

  const getDetail = async () => {
    const response = await axios({
      apiPath: "auction/" + slug,
      method: "GET",
    });

    if (response && response.status) {
      setAuction(response.data);
      setTimeout(() => {
        setEditorState(true);
      }, 500);
    }
  };

  const uploadImage = async (file) => {
    const formData = new FormData();
    formData.append("image", file);
    const response = await axios({
      apiPath: "upload/image",
      method: "POST",
      payload: formData,
      isFile: true,
    });
    if (response && response.status)
      updateAuction("image", response.data.image_url);
  };

  useEffect(() => {
    if (submitType === "update") return getDetail();
    setEditorState(true);
  }, [slug]);

  // Chakra Color Mode
  return (
    <Card mb={{ base: "0px", lg: "20px" }} mt={20} align="center">
      <form>
        <SimpleGrid mb="20px" spacing={{ base: "20px", xl: "20px" }}>
          <FormControl id="title" isRequired>
            <FormLabel>Title</FormLabel>
            <Input
              placeholder="Title"
              borderRadius="16px"
              value={auction.title}
              onChange={(event) => updateAuction("title", event.target.value)}
            />
          </FormControl>
          {editorState && (
            <FormControl id="description" isRequired>
              <FormLabel>Description</FormLabel>
              <CKEditor
                editor={ClassicEditor}
                data={auction.description}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  updateAuction("description", data);
                }}
              />
            </FormControl>
          )}
          <FormControl id="image" isRequired mb={10}>
            <FormLabel>Image</FormLabel>
            <Dropzone
              content={
                <Box>
                  <Flex justify="center" mx="auto" mb="12px">
                    <Text fontSize="xl" fontWeight="700" color={brandColor}>
                      Upload File
                    </Text>
                  </Flex>
                  <Text
                    fontSize="sm"
                    fontWeight="500"
                    color="secondaryGray.500">
                    PNG and JPG files are allowed
                  </Text>
                </Box>
              }
              onDrop={(file) => uploadImage(file[0])}
            />
          </FormControl>
          {auction.image && (
            <Box mb={{ base: "20px", "2xl": "20px" }}>
              <Image src={auction.image} w="48" h="48" borderRadius="20px" />
            </Box>
          )}
          <SimpleGrid columns={2} gap={5}>
            <FormControl id="starting_date" isRequired>
              <FormLabel>Auction Starting Date</FormLabel>
              <Input
                placeholder="Select Date and Time"
                size="md"
                type="datetime-local"
                value={moment(auction.starting_date).format("YYYY-MM-DDTkk:mm")}
                onChange={(event) =>
                  updateAuction(
                    "starting_date",
                    new Date(event.target.value).getTime()
                  )
                }
              />
            </FormControl>
            <FormControl id="ending_date" isRequired>
              <FormLabel>Auction Ending Date</FormLabel>
              <Input
                placeholder="Select Date and Time"
                size="md"
                type="datetime-local"
                value={moment(auction.ending_date).format("YYYY-MM-DDTkk:mm")}
                onChange={(event) =>
                  updateAuction(
                    "ending_date",
                    new Date(event.target.value).getTime()
                  )
                }
              />
            </FormControl>
          </SimpleGrid>
          <FormControl id="lots" isRequired>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              mb={5}>
              <FormLabel fontWeight={"bold"} fontSize={"2xl"}>
                Lots
              </FormLabel>
              <Button
                variant="darkBrand"
                color={"white"}
                onClick={() => addLot()}>
                Add new Lot
              </Button>
            </Box>
            {auction.lots.map((lot, index) => (
              <Box key={index} marginBottom={10}>
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Text fontWeight={"bold"} fontSize={"xl"}>
                    Lot {index + 1}
                  </Text>
                  <Button
                    variant="solid"
                    bgColor={"red.400"}
                    color={"white"}
                    onClick={() => removeLot(index)}>
                    <CgTrash />
                  </Button>
                </Box>
                <SimpleGrid columns={{ base: 2 }} columnGap={5}>
                  <FormControl id={`${index}-lot-artwork`} isRequired>
                    <FormLabel>Artwork</FormLabel>
                    <AsyncSelect
                      placeholder={
                        slug && lot.artwork ? getLabel(lot.artwork) : "Search"
                      }
                      cacheOptions
                      loadOptions={getArtworkList}
                      defaultOptions
                      onChange={(item) => {
                        console.log(item.value);
                        updateLot(index, "artwork", item.value);
                      }}
                    />
                  </FormControl>
                  <FormControl
                    id={`${index}-lot-starting_lot_price`}
                    isRequired>
                    <FormLabel>Starting Lot Price</FormLabel>
                    <Input
                      placeholder="Starting Bid Price"
                      borderRadius="16px"
                      type="number"
                      value={auction.lots[index].starting_lot_price}
                      onChange={(event) =>
                        updateLot(
                          index,
                          "starting_lot_price",
                          Number(event.target.value)
                        )
                      }
                    />
                  </FormControl>
                </SimpleGrid>
                <SimpleGrid columns={{ base: 2 }} columnGap={5} my={5}>
                  <FormControl
                    id={`${index}-lot-estimated_starting_price`}
                    isRequired>
                    <FormLabel>Estimated Starting Lot Value</FormLabel>
                    <Input
                      placeholder="Estimated Starting Lot Value"
                      borderRadius="16px"
                      value={auction.lots[index].estimated_starting_price}
                      type="number"
                      onChange={(event) =>
                        updateLot(
                          index,
                          "estimated_starting_price",
                          Number(event.target.value)
                        )
                      }
                    />
                  </FormControl>
                  <FormControl id={`${index}-lot-estimated_ending_price`}>
                    <FormLabel>Estimated Ending Lot Value</FormLabel>
                    <Input
                      placeholder="Estimated Starting Lot Value"
                      borderRadius="16px"
                      type="number"
                      value={auction.lots[index].estimated_ending_price}
                      onChange={(event) =>
                        updateLot(
                          index,
                          "estimated_ending_price",
                          Number(event.target.value)
                        )
                      }
                    />
                  </FormControl>
                </SimpleGrid>
                <SimpleGrid columns={{ base: 2 }} columnGap={5}>
                  <FormControl id={`${index}-lot-starting_date`} isRequired>
                    <FormLabel>Starting Lot Date</FormLabel>
                    <Input
                      placeholder="Select Date and Time"
                      size="md"
                      type="datetime-local"
                      value={moment(auction.lots[index].starting_date).format(
                        "YYYY-MM-DDTkk:mm"
                      )}
                      onChange={(event) =>
                        updateLot(
                          index,
                          "starting_date",
                          new Date(event.target.value).getTime()
                        )
                      }
                    />
                  </FormControl>
                  <FormControl id={`${index}-lot-ending_date`} isRequired>
                    <FormLabel>Ending Lot Date</FormLabel>
                    <Input
                      placeholder="Select Date and Time"
                      size="md"
                      type="datetime-local"
                      value={moment(auction.lots[index].ending_date).format(
                        "YYYY-MM-DDTkk:mm"
                      )}
                      onChange={(event) =>
                        updateLot(
                          index,
                          "ending_date",
                          new Date(event.target.value).getTime()
                        )
                      }
                    />
                  </FormControl>
                </SimpleGrid>
              </Box>
            ))}
          </FormControl>

          <Button
            isLoading={isLoading}
            onClick={() => createAuction()}
            variant="darkBrand"
            color="white"
            fontSize="sm"
            fontWeight="500"
            borderRadius="70px"
            px="24px"
            py="5px">
            {submitType === "create" ? "Create" : "Update"}
          </Button>
        </SimpleGrid>
      </form>
    </Card>
  );
}
