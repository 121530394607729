import axios from "axios";
import toast from "utils/useToast";

const onRequest = async ({ apiPath, method, payload, baseURL, isFile }) => {
  const axiosConfig = {
    // baseURL: baseURL || "http://localhost:8000/api/",
    baseURL: "https://artxchange-api.onrender.com/api",
    url: apiPath,
    method: method || "POST",
    headers: {
      Accept: "*/*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + (await localStorage.getItem("token")),
    },
    withCredentials: false,
    // withCredentials: true,
    data: payload,
  };

  if (isFile) axiosConfig.headers["Content-Type"] = "multipart/form-data";

  try {
    const response = await axios(axiosConfig);

    if (!response.data.status) throw new Error(response.data.message);

    if (response.data.status) {
      if (method !== "GET") toast("success", response.data.message);
      return response.data;
    }

    return toast("error", response.data.message);
  } catch (error) {
    if (
      error.message &&
      (error.message === "UnauthorizedError" ||
        error.message === "There is no token")
    ) {
      window.location.href = window.location.origin + "/#/auth/sign-in";
    }

    toast(
      "error",
      error.response ? error.response.data.message : error.message
    );
    if (error.response) return error.response.data;
    return error.message;
  }
};

const useAxios = async (requestData) => {
  if (requestData) return await onRequest({ ...requestData });
};

export default useAxios;
